<template>
  <a-form :form="form">
    <a-form-item label="榜单类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入榜单类型"
        v-decorator="['type', { initialValue: record.type }]"
      />
    </a-form-item>
    <a-form-item
      label="榜单所属行业"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
    >
      <a-input
        placeholder="请输入榜单所属行业"
        v-decorator="['sector', { initialValue: record.sector }]"
      />
    </a-form-item>
    <a-form-item label="榜单年份" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="['year', { initialValue: record.year }]"
        type="number"
        placeholder="请输入榜单年份"
      />
    </a-form-item>
    <a-form-item label="分数" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        v-decorator="['score', { initialValue: record.score }]"
        type="number"
        placeholder="请输入分数"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import { addScore, editScore } from "@/api/organization";

export default {
  name: "TaskForm",
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
    };
  },
  methods: {
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            let formData = {
              organizationId: this.record.organizationId,
              type: values.type,
              sector: values.sector,
              year: values.year,
              score: values.score,
            };
            if (this.record.id) {
              // 更新
              let data = {
                id: this.record.id,
                ...formData,
              };
              editScore(data).then((res) => {
                if (res.code == 0) {
                  this.$message.success("更新成功");
                  resolve(true);
                }
              });
            } else {
              // 创建
              addScore(formData).then((res) => {
                if (res.code == 0) {
                  this.$message.success("添加成功！");
                  resolve(true);
                }
              });
            }
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>

<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
