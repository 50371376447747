<template>
  <div>
    <a-button
      v-if="hasAction('edit-org-score-info')"
      type="primary"
      icon="plus"
      @click="handleAdd()"
    >
      新增榜单信息
    </a-button>
    <a-table
      :columns="topList.columns"
      :dataSource="topList.dataSource"
      :loading="ui.loading"
      rowKey="id"
      :pagination="{ pageSize: 10 }"
    >
      <template slot="dataManage" slot-scope="text, record">
        <a @click="handleEdit(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          title="确认移除该条信息吗？"
          ok-text="是"
          cancel-text="否"
          @confirm="handleDelete(record)"
        >
          <a>删除</a>
        </a-popconfirm>
      </template></a-table
    >
  </div>
</template>

<script>
import { getScoreList, deleteScore } from "@/api/organization";
import FinanceForm from "./components/FinanceForm";

const topListColumns = [
  {
    title: "榜单类型",
    dataIndex: "type",
  },
  {
    title: "榜单所属行业",
    dataIndex: "sector",
  },
  {
    title: "榜单年份",
    dataIndex: "year",
  },
  {
    title: "分数",
    dataIndex: "score",
  },
];

export default {
  name: "organization-top-list-manage",
  data() {
    return {
      topList: {
        columns: topListColumns,
        dataSource: [],
        info: {},
      },
      ui: {},
    };
  },
  created() {
    if (
      this.hasAction("edit-org-score-info") &&
      this.topList.columns.length < 5
    ) {
      this.topList.columns.push({
        title: "管理",
        dataIndex: "finance-manage",
        scopedSlots: {
          customRender: "dataManage",
        },
      });
    }
    this.organizationId = this.$route.params.organizationId;
    this.companyId = this.$route.params.companyId;
    this.loadData();
  },
  methods: {
    loadData() {
      this.ui.loading = true;
      getScoreList({
        organizationId: this.organizationId,
      }).then((res) => {
        this.topList.dataSource = res.data;
        this.ui.loading = false;
      });
    },
    handleAdd() {
      let self = this;
      this.$dialog(
        FinanceForm,
        {
          record: { organizationId: this.organizationId },
          on: {
            ok: () => {
              self.loadData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "新增",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleEdit(record) {
      this.$dialog(
        FinanceForm,
        {
          record: record,
          on: {
            ok: () => {
              this.loadData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "编辑",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleDelete(record) {
      deleteScore(record).then(() => {
        this.loadData();
        this.$message.success("删除成功！");
      });
    },
  },
};
</script>
